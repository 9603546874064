import { useHistory } from 'react-router-dom';

import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';

import { PLUSPediatric, PLUSPrescriptionBenefit, RecuroLogo } from '../../../assets';
import getPartnerResourceConfig from '../partner-resource-config';
import { Spinner } from '../../../components/common';
import { useExternalProviderPlan } from '../../../hooks/useExternalProviderPlans';
import { useUser } from '../../../hooks/useUser';
import { ResourceDetailView } from '../resource-detail';

export const PediatricPage: React.FC = () => {
  const { data: user, isLoading: userLoading } = useUser();

  // TODO: add this line in to get the ssoLinkUrl
  // const { data: ssoLinkUrl, isLoading: ssoLinkUrlPending } = useTelemedRedirect(user?.id || 0);
  const ssoLinkUrl = { redirect_url: 'https://member.recurohealth.com' };

  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;
  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);
  const navigate = useHistory();

  if (externalPlanLoading || userLoading) return <Spinner />;

  const name = '+Prescription Benefit';
  const description = 'Comprehensive mental wellness support for children and families.';
  const longDescription = (
    <div className="flex flex-col gap-y-4">
      Your benefit includes virtual access to comprehensive behavioral and mental health support. From school pressures,
      to external stressors, social anxieties, and self-esteem issues – families are dealing with a lot. Pediatric
      Behavioral Health provides coaching programs to help with everyday challenges, counseling for common childhood
      conditions, and a digital platform for support on-the-go to help families thrive. Parental support through
      self-guided and coach-supported skills training is also available for parents and caregivers.
      <div>
        COMMON USES:
        <ul>
          <li>School Pressure</li>
          <li>Stress</li>
          <li>Anxiety</li>
          <li>Bullying</li>
          <li>Confidence</li>
          <li>Self-esteem</li>
          <li>And more</li>
        </ul>
      </div>
      <div>ONLINE ACCESS: Click the link above to schedule a visit online.</div>
      <div>
        PHONE ACCESS: Schedule a visit by phone by calling <div>855-6RECURO (855-673-2876)</div>
      </div>
    </div>
  );

  // TODO unclear from mocks whether we're still going to display Recuro details...leaving in unused for now
  const providerText =
    'PLUS services are provided independently by Recuro Health. © 2023 Recuro Health, Inc. All rights reserved. Recuro Health, Recuro, and the Recuro logo are trademarks of Recuro Health, Inc. and may not be used without written permission. Recuro Health does not guarantee that a physician will write a prescription, and physicians do not prescribe DEA controlled substances, non-therapeutic drugs and certain other drugs which may be harmful because of their potential for abuse. Recuro Health affiliated physicians reserve the right to deny care for potential misuse of services. Medical services provided by physicians are subject to their professional judgment. Recuro Health operates subject to state regulation and some services may not be available in certain states.';
  const providerImage = (
    <div className="w-80 h-48 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
      <img className="min-w-full mb-3" src={RecuroLogo} alt="Recuro Crisis Plan" />
    </div>
  );

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        url={providerPlan?.provider === 'recuro' ? 'https://member.recurohealth.com' : ssoLinkUrl?.redirect_url}
        urlDescription={''}
        phoneNumber={providerPlan?.provider === 'recuro' ? '8006993542' : undefined}
        callButtonText={'Schedule Visit By Phone'}
        ctaButtonText={'Schedule Visit Online'}
        imageUrl={PLUSPediatric}
        tagNames={['Behavioral Health']}
        description={longDescription}
        clientLogoUrl={user?.caller_role.active_subscription?.package?.client?.comms_logo_file_url}
        partnerResourceConfig={partnerResourceConfig}
      />
    </div>
  );
};
