import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPut, axiosPost, axiosPostV3 } from '../api/axios-handler';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../routes/route-paths';
import { TagFilters } from '../components/filter/FomSelectionFilter';
import { peersQueryKeys } from './usePeers';
import { experiencesQueryKeys } from './useExperiences';
import { useTagGroups } from './useTagGroups';
import { resourceKeys } from './useResources';
import { featureFlagKeys } from './useFeatureFlag';
import { analytics, EventTypes } from '@kindlyhuman/component-library';
import Toast from '../components/common/PopUpMessage';
import moment from 'moment';
import useAuth from './useAuth';

export const userQueryKeys = {
  me: ['me'] as const,
  partial: ['partial'] as const,
};

export interface User {
  [key: string]: any;
  authorization_token?: string;
  access_role: any;
  administrator_role: any;
  administrator_role_id: any;
  api_key: string;
  caller_role: CallerRole;
  caller_role_id: number;
  password?: string;
  city: string;
  client_administrator_role: any;
  client_administrator_role_id: any;
  client_id: number;
  client_name: string;
  counseling_configuration: any;
  created_at: string;
  current_app_version: string;
  date_of_birth: string;
  dialcare_status: DialcareStatus;
  display_name: string;
  display_name_rejected: boolean;
  email_address: string;
  family: string | null;
  first_name: string;
  gender: string | null;
  hide_payment_tiles: boolean;
  how_did_you_hear: any;
  has_peers: boolean;
  id: number;
  is_dependent: boolean;
  is_partial: boolean;
  is_text_compatible_phone: boolean;
  languages: string[];
  last_active_at: string;
  last_name: string;
  listener_role: ListenerRole;
  listener_role_id: number;
  masquerading: boolean;
  military_branch: any;
  military_role: any;
  mobile_phone: string;
  mobile_phone_verification_started_at: string;
  mobile_phone_verification_token: string;
  mobile_phone_verified: boolean;
  needs_password: boolean;
  password_reset_token: string;
  password_reset_token_created_at: string;
  pronoun: string | null;
  proposed_display_name: any;
  race: string | null;
  relationship: string | null;
  relationship_type: string;
  search_queries: SearchQueries[] | null;
  spirituality: any;
  state: string;
  status: string;
  street_address_1: string;
  street_address_2: string;
  test_user: boolean;
  timezone: string;
  user_subscription: UserSubscription;
  zip_code: string;

  onUnlimitedPlan?: boolean;
  onCappedPlan?: boolean;
  onMemberPayPlan?: boolean;
  onSelfPayPlan?: boolean;
}

interface PaymentData {
  available_minutes: number;
  plan_payment_type: 'CAPPED_ACCESS' | 'UNLIMITED_ACCESS' | 'MEMBER_PAY' | 'SELF_PAY';
  rate_per_minute: number;
  total_monthly_minutes: number;
}

interface MemberSignupRequest {
  email_address: string;
  package_code: string;
}

export interface MemberSignupResponse {
  caller_role: MemberSignupCallerRole;
  created_at: Date;
  email_address: string;
  id: number;
  mobile_phone: string;
}

export interface MemberSignupCallerRole {
  created_at: Date;
  id: number;
  listener_preferences: any;
}

export interface CallerRole {
  created_at: string;
  id: number;
  is_call_units?: boolean;
  listener_preferences: ListenerPreferences;
  notifications_check_ins: boolean;
  notifications_incoming_call: boolean;
  notifications_listener_online: boolean;
  notifications_resources: boolean;
  active_subscription?: PackageSubscription;
  package_subscriptions?: PackageSubscription[];
  status: string;
  stripe_customer_id: string;
  stripe_payment_failed: boolean;
  stripe_payment_failure_code: any;
  stripe_payment_method_id: string;
  tag_group_ids: number[] | null;
  tag_ids: number[] | null;
  total_available_credit: any;
  type: any;
  payment_data: PaymentData;
}

export interface ListenerPreferences {
  age_range: AgeRange;
  all_topics: boolean;
  genders: Genders;
  languages: string[];
  preferred_language: string;
  topic_tags: number[];
  topics: number[];
}

export interface AgeRange {
  from: number;
  to: number;
}

export interface Genders {
  female: boolean;
  male: boolean;
  other: boolean;
}

export interface PackageSubscription {
  created_at: string;
  start_date: string;
  end_date: Date;
  package: Package;
  package_id: number;
}

export interface Package {
  archived_at: any;
  benefits_description: string;
  branding_configuration: BrandingConfiguration;
  can_manage_dependents: boolean;
  cost_per_minute_cents: number;
  client: Client;
  code: string;
  collect_user_address: boolean;
  dependents_configuration: DependentsConfiguration;
  external_plan_id: number;
  force_web_app: any;
  id: number;
  my_plan_file_url: string;
  name: string;
  package_products: PackageProduct[];
  subscriptions: any[];
  theme_name: any;
}

export interface BrandingConfiguration {
  button_label: string;
  enabled: boolean;
  image_url: string;
  placements: string[];
  type: string;
  url: string;
  video_url: any;
}

// TODO this type is also defined in usePackageDetails.tsx and useClientCode.tsx.
// These should be consolidated into a single declaration.
export interface Client {
  id: number;
  landing_page_content: any;
  logo_background_file_url: any;
  logo_file_url: string;
  comms_logo_file_url: string;
  logo_overlay_file_url: any;
  member_self_enrollment: boolean;
  name: string;
  promo_configuration: PromoConfiguration;
  sso_configuration?: any;
  video_configuration: VideoConfiguration;
  client_code: string;
  powered_by_header_file_url: string;
  powered_by_header_enabled: boolean;
  group_banner_file_url: string;
  group_banner_enabled: boolean;
  use_global_resources: boolean;
}

export interface PromoConfiguration {
  button_label: string;
  image_url: string;
  placements: string[];
  type: string;
  url: string;
  video_url: string;
}

export interface VideoConfiguration {
  image_url: string;
  title: string;
  video_url: string;
}

export interface DependentsConfiguration {
  dependent_count: number;
  enabled: boolean;
  members_can_add_dependents: boolean;
}

export interface PackageProduct {
  configuration: Configuration;
  created_at: string;
  id: number;
  package_id: number;
  product: Product;
  product_id: number;
  status: string;
}

export interface Configuration {
  image_file?: string;
  required_points?: number;
  reward_value?: number;
  auto_renew_frequency?: string;
  engagement_points?: number;
  interim_question?: string;
  primary_question?: string;
  hide_payment_tiles?: boolean;
  maximum_minutes_per_user?: number;
}

export interface Product {
  id: number;
  key: string;
  product_type: string;
}

export interface DialcareStatus {
  has_address: boolean;
  processing_date: string;
}

export interface ListenerRole {
  about_me: any;
  about_me_rejected: boolean;
  availability_changes_at: any;
  availability_changes_to: any;
  available: boolean;
  background_check_fee_collected: any;
  background_check_reward_applied: any;
  background_check_verified: boolean;
  background_traits: any[];
  call_rate: any;
  calls_succeeded: number;
  can_take_calls: boolean;
  checkr_id: any;
  completed_training: CompletedTraining;
  connection_blocked: boolean;
  connection_passes: number;
  connection_requests: number;
  created_at: string;
  first_active_listener_at: any;
  first_active_peer_at: any;
  has_completed_listener_guide: boolean;
  has_completed_peer_guide: boolean;
  has_lobby_subscription: boolean;
  has_required_experiences: boolean;
  has_required_office_hours: boolean;
  has_required_story: boolean;
  id: number;
  is_active: boolean;
  is_peer_active: boolean;
  is_reviewable: boolean;
  is_volunteer: boolean;
  last_tier_evaluation_at: string;
  military_tag: any;
  notifications_caller_online: boolean;
  notifications_incoming_call: boolean;
  notifications_resources: boolean;
  peer_status: string;
  premier_listener: boolean;
  primary_language: string;
  primary_language_tag: string;
  profile_photo_approved: boolean;
  profile_photo_file_url: string;
  profile_photo_modified_at: string;
  profile_photo_square_file_url: any;
  profile_traits: string[];
  proposed_about_me: any;
  refer_peer_org: any;
  refer_platform: any;
  required_training_complete: boolean;
  secondary_language: any;
  secondary_language_tag: string;
  share_link_url: string;
  spirituality: string;
  spirituality_tag: string;
  state: string;
  status: string;
  stripe_connect_state_token: any;
  stripe_customer_id: any;
  stripe_payment_intent_id: any;
  stripe_payment_method_id: any;
  stripe_user_id: any;
  tier: Tier;
  tier_id: number;
  type: any;
}

export interface CompletedTraining {}

export interface Tier {
  call_bonus: number;
  call_earnings: number;
  experience_bonus: number;
  experience_listen_rate: number;
  id: number;
  is_default: boolean;
  max_daily_office_hours: number;
  max_experience_payouts: number;
  minimum_office_hours: number;
  name: string;
  office_hour_rate: number;
  paid_office_hours_cap: number;
  pay_office_hours: boolean;
  sort_weight: number;
  status: string;
}

export interface SearchQueries {
  created_at: string;
  query: string;
}

export interface UserSubscription {
  created_at: string;
  id: number;
  remaining_minutes: number;
  status: string;
  subscribed_on_day: number;
  subscription: Subscription;
  subscription_id: number;
}

export interface Subscription {
  cents_per_minute: number;
  code: string;
  id: number;
  name: string;
}

export interface Authenticate {
  email_address: string;
  password: string;
  trusted: boolean;
}

export interface PasswordReset {
  email_address: string;
  from_location?: string;
}

interface PartialMemberRequest {
  email_address?: string;
  user_id?: number;
}

export interface PartialMember {
  user_id: number;
  user_email_obfuscated: string;
  user_phone_obfuscated: string;
  package_code: string;
}

const getUser = (): Promise<User> => {
  return axiosGet(`/users/me`, {}).then((userData) => {
    if (userData.data.user_id_hash) {
      analytics.trackEvent(EventTypes.SET_USER_ID, userData.data.user_id_hash);
    }
    if (userData.data?.caller_role && userData.data.caller_role.active_subscription) {
      const activeSubscription = userData.data.caller_role.active_subscription;
      const isTestUser = userData.data.test_user || activeSubscription.package.client.is_test;
      const age = Math.floor(moment().diff(userData.data.date_of_birth, 'years') / 10) * 10;
      const userDecade = `${age}s`;
      const clientCode = activeSubscription.package.client.client_code;
      analytics.trackEvent(
        EventTypes.SET_USER_DATA,
        activeSubscription.package.code,
        isTestUser,
        clientCode,
        userData?.data?.gender,
        age > 150 ? undefined : userDecade,
      );
    }

    return userData.data;
  });
};

export const getPartialMember = (
  request: PartialMemberRequest,
  onSuccess: (data: PartialMember) => void,
  onError: (err: AxiosError) => void,
  onFinally?: () => void,
): void => {
  axiosGet(`/members/partial`, request)
    .then((partialMember) => onSuccess(partialMember.data))
    .catch((err: AxiosError) => {
      onError(err);
    })
    .finally(onFinally);
};

export const useUser = () => {
  const history = useHistory();
  const navigate = useHistory();
  const queryClient = useQueryClient();
  const { formatTagsAndTagGroups } = useTagGroups();
  const { onUserAuthenticated, logout, authToken, setAuthToken } = useAuth();

  const query = useQuery<User>(
    userQueryKeys.me,
    () => {
      // onSuccess handler is deprecated and was invoked even when using a cached query,
      // so moving invalidation here reduces unnecessary invalidaton + additional requests
      queryClient.invalidateQueries(featureFlagKeys.featureFlags);
      return getUser();
    },
    {
      // refetch user every 30 minutes just to be safe. this could potentially be Infinity
      staleTime: 1000 * 60 * 30,

      //@TODO, useState is not immediately updated, so using for enabled here
      //results in retries against a known 403 response on logout
      enabled: Boolean(authToken),
      select: (user) => {
        if (user && user.caller_role.package_subscriptions?.length === 0) {
          delete user.caller_role.package_subscriptions;
        }
        user.onUnlimitedPlan = user?.caller_role.payment_data.plan_payment_type === 'UNLIMITED_ACCESS';
        user.onCappedPlan = user?.caller_role.payment_data.plan_payment_type === 'CAPPED_ACCESS';
        user.onMemberPayPlan = user?.caller_role.payment_data.plan_payment_type === 'MEMBER_PAY';
        user.onSelfPayPlan = user?.caller_role.payment_data.plan_payment_type === 'SELF_PAY';

        user.is_sso = !!user?.caller_role.active_subscription?.package.client.sso_configuration;

        if (!user?.caller_role?.active_subscription) {
          setAuthToken(null);
          queryClient.removeQueries(userQueryKeys.me);
          history.push(ROUTE_PATH.WELCOME_PAGE);

          Toast.error(
            `Your member plan has expired. Please contact your organization's administrator to renew your plan.`,
          );
        }

        return user;
      },
      onError: (err: any) => {
        if (err.response?.status === 403) {
          setAuthToken(null);
          queryClient.removeQueries(userQueryKeys.me);
          history.replace(ROUTE_PATH.LOGIN);
        }
      },
    },
  );

  const updateTagPreferences = useMutation(
    ({ tag_group_ids, tag_ids }: TagFilters) => {
      return axiosPut(`/users/${query.data?.id}`, {
        caller_role: {
          ...formatTagsAndTagGroups(tag_group_ids, tag_ids),
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userQueryKeys.me);
        queryClient.invalidateQueries({ queryKey: peersQueryKeys.favorites });
        queryClient.invalidateQueries({ queryKey: peersQueryKeys.new });
        queryClient.invalidateQueries({ queryKey: experiencesQueryKeys.recommended });
        queryClient.invalidateQueries({ queryKey: resourceKeys.all });
        queryClient.removeQueries({ queryKey: experiencesQueryKeys.infiniteRecommended });
        queryClient.removeQueries({ queryKey: peersQueryKeys.infiniteRecommended });
        queryClient.invalidateQueries({ queryKey: peersQueryKeys.infiniteRecommended });
        queryClient.removeQueries(['textSearchExperiences']);
        queryClient.cancelQueries(['textSearchExperiences']);
      },
      onError: () => {
        queryClient.invalidateQueries(userQueryKeys.me);
      },
      onMutate: ({ tag_group_ids, tag_ids }) => {
        queryClient.setQueryData(userQueryKeys.me, (previousUser: any) => {
          return {
            ...previousUser,
            caller_role: {
              ...previousUser.caller_role,
              ...formatTagsAndTagGroups(tag_group_ids, tag_ids),
            },
          };
        });
      },
    },
  );

  const resendOnboardingLink = useMutation(({ userId, type }: { userId: number; type: 'text' | 'email' }) => {
    return axiosPost(`/users/${userId.toString()}/resend_onboarding_link`, { type }, 'v2');
  });

  const sendKeycloakVerificationLink = useMutation(({ userId }: { userId: number }) => {
    return axiosPost(`/users/${userId.toString()}/keycloak_verification`, {}, 'v3');
  });

  const send2FAEmail = useMutation(({ email_address }: { email_address: string }) => {
    return axiosPostV3(`/users/send_mfa`, { email_address });
  });

  const redeemPasswordRequest = useMutation(
    (payload: any) => axiosPost(`/users/redeem_password_reset`, { ...payload }),
    {
      onSuccess: () => {
        history.push(ROUTE_PATH.LOGIN);
      },
    },
  );

  const updateUser = useMutation(
    (input: Partial<User>) => {
      return axiosPut(`/users/${query.data?.id}`, {
        ...input,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['me']);
        queryClient.invalidateQueries(['paymentMethod']);
        queryClient.invalidateQueries(['stripeKeys']);
      },
    },
  );

  const removePaymentMethod = useMutation(
    (userId: number) => {
      return axiosPost(`/users/${userId}/stripe_remove_payment_method`, {});
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['me']);
      },
    },
  );

  const authenticate = useMutation(
    ({
      email_address,
      password,
      trusted,
      setDisabled,
      next,
    }: Authenticate & { setDisabled: React.Dispatch<React.SetStateAction<boolean>>; next: string | null }) => {
      return axiosPost(`/users/authenticate`, {
        email_address,
        password,
        trusted,
      }).then((response) => {
        analytics.trackEvent(EventTypes.LOGIN, 'WebApp');
        return response;
      });
    },
    {
      onSuccess: (res: AxiosResponse<User>, { setDisabled, next }) => {
        return onUserAuthenticated({
          isListenerRole: Boolean(res.data.listener_role),
          isCallerRole: Boolean(res.data.caller_role),
          authToken: res.data.authorization_token!,
          next,
        });
      },
      onError: () => {
        Toast.error('Email and/or password is not correct.');
      },
      onSettled: (data, error, { setDisabled }) => {
        setDisabled(false);
      },
    },
  );

  const passwordReset = useMutation(({ email_address, from_location = 'mwa' }: PasswordReset) => {
    return axiosPost(`/users/request_password_reset`, null, 'v2', {
      params: {
        email_address,
        from_location,
      },
    });
  });

  const authenticateWithToken = useMutation(
    ({ token, userId }: { token: string; userId: number }) => {
      const data = {
        token,
      };
      return axiosPost(`/users/${userId}/authenticate_with_token`, data, 'v3');
    },
    {
      onSuccess: (result: any) => {
        setAuthToken(result.data.authorization_token);
        analytics.trackEvent(EventTypes.LOGIN, 'SmartLink');
        queryClient.invalidateQueries(userQueryKeys.me);
        navigate.push(ROUTE_PATH.ONBOARDING);
      },
    },
  );

  const authenticateWithSSOToken = useMutation(
    ({ token }: { token: string }) => {
      return axiosPost('/users/authenticate_with_sso_token', { token }, 'v3').then((response) => response.data);
    },
    {
      onSuccess: (result: User) => {
        setAuthToken(result?.authorization_token ?? null);
        analytics.trackEvent(EventTypes.LOGIN, 'SSO');
        queryClient.invalidateQueries(userQueryKeys.me);
        navigate.push(result.is_partial ? ROUTE_PATH.WELCOME_PAGE : ROUTE_PATH.HOME);
      },
    },
  );

  const signupUser = useMutation(
    ({ email_address, package_code }: MemberSignupRequest) => {
      return axiosPost(
        '/members/',
        {
          email_address,
          package_code,
        },
        'v3',
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userQueryKeys.me);
      },
    },
  );

  return {
    ...query,
    authenticateWithToken,
    send2FAEmail,
    authenticateWithSSOToken,
    updateTagPreferences,
    signupUser,
    resendOnboardingLink,
    sendKeycloakVerificationLink,
    logout,
    setAuthToken,
    authenticate,
    passwordReset,
    updateUser,
    removePaymentMethod,
    redeemPasswordRequest,
  };
};

interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  userId: number;
}

export const useChangePasswordMutation = () =>
  useMutation(({ currentPassword: current_password, newPassword: new_password, userId }: ChangePasswordRequest) => {
    return axiosPost(`/users/${userId}/change_password`, {
      current_password,
      new_password,
    });
  });

interface RedeemAuthCodeForTokenResponse {
  access_token: string;
  refresh_token: string;
}

export const redeemAuthCodeForToken = async (code: string): Promise<RedeemAuthCodeForTokenResponse> => {
  return await axiosPost(`/users/redeem_code/mwa`, { code: code }, 'v3').then((response) => {
    return response.data;
  });
};
